import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Markdown = styled.div`
  padding-top: 3px;
  text-align: left;

  .editor-toolbar {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -0.054px;
    line-height: 1.58;
    margin-bottom: 0;
    margin-top: 29px;

    @media only screen and (max-width: 480px) {
      font-size: 18px;
      margin-top: 19px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 1em;
    font-weight: bold;
  }

  h1 {
    font-size: 44px;
    padding-top: 16px;
    font-weight: 400;
    line-height: 1.2;

    @media only screen and (max-width: 480px) {
      font-size: 34px;
      padding-top: 5px;
    }
  }

  h1:first-child {
    margin-top: 0;
  }

  h2 {
    font-size: 36px;
    margin-top: 56px;
    font-weight: 300;
    line-height: 1.2;

    @media only screen and (max-width: 480px) {
      font-size: 30px;
      margin-top: 34px;
    }
  }

  h3 {
    font-size: 30px;
    padding-top: 16px;
    margin-top: 56px;
    font-weight: 100;
    line-height: 1.2;

    @media only screen and (max-width: 480px) {
      font-size: 26px;
      margin-top: 34px;
    }
  }

  h4 {
    margin-top: 1em;
    font-size: 1em;
  }

  ul, ol {
    padding-left: 20px;

    li {
      font-size: 21px;
      margin-top: 16px;
      font-weight: 300;
      letter-spacing: -0.054px;
      line-height: 1.58;
      margin-bottom: 0;
      padding-bottom: 0;

      @media only screen and (max-width: 480px) {
        font-size: 18px;
        margin-top: 19px;
      }
    }
  }

  img {
    display: block;
    margin: 20px auto;
    max-width: 100%;
  }

  details {
    padding: 0 1rem;
    position: relative;
    border: 1px solid #e8e8e8;
    background-color: #fafafa;
    color: #263238;
    transition: height 300ms ease-in-out;

    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      opacity: 0;
      box-shadow: 0 0.25em 0.5em #263238;
      pointer-events: none;
      transition: opacity 0.2s;
      z-index: -1;
    }

    &[open] {
      background-color: #fff;
    }

    p {
      animation: ${fadeIn} 0.5s;
    }
  }

  summary {
    user-select: none;
    position: relative;
    list-style: none;
    padding: 0.5rem 2em 0.5rem 0;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    transition: color 300ms ease-in-out;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::before,
  summary::after {
    content: "";
    position: absolute;
  }

  summary::before {
    width: 16px;
    height: 2px;
    right: 0;
    top: 50%;
    margin-top: -1px;
    background-color: rgb(0 0 0 / 45%);
  }

  summary::after {
    width: 2px;
    height: 16px;
    right: 0;
    top: 50%;
    margin-top: -8px;
    margin-right: 7px;
    transition: all 300ms ease-in-out;
    background-color: rgb(0 0 0 / 45%);
  }

  [open] > summary {
    color: rgb(0 0 0 / 45%);
  }

  [open] summary::after {
    opacity: 0;
    transform: translateY(25%);
  }

  table {
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    word-wrap: break-word;
  }

  table tr {
    border-top: 1px solid #ccc;
    background-color: white;
    margin: 0;
    padding: 0;
  }

  table tr:nth-child(2n) {
    background-color: #f8f8f8;
  }

  table tr th {
    font-weight: bold;
    border: 1px solid #ccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
    background-color: #e8e8e8;
  }

  table tr td {
    border: 1px solid #ccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }

  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }

  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }

  @media print {
    table {
      page-break-before: auto;
      page-break-inside: auto;
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      break-after: avoid;
    }

    p,
    ul {
      orphans: 3;
      widows: 3;
    }
  }
`;

export default Markdown;
